import React from 'react'
import {UserProfilePage} from './user/Profile'
import {UserPasswordPage} from "./user/Password"
import {UserNotifyPage} from "./user/Notify"

export const ProfilePage = (props) => {

    if (props && props.page === 'Profile') return <UserProfilePage />
    if (props && props.page === 'Password') return <UserPasswordPage />
    if (props && props.page === 'Notification') return <UserNotifyPage />

}