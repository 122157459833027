import React from 'react'

export const Tag = ({uid=+new Date(), name='', style='green', css={}, ml='5px', mr='5px', onClick=(sid) => {console.log('uid', sid)}}) => {

    let tagStyleGreen = {color: '#4bb0b0', border: '1px solid #4bb0b0', borderRadius: '5px', height: '25px', maxWidth: '250px'}
    let tagStyleRed = {color: '#e47b0e', border: '1px solid #e47b0e', borderRadius: '5px', height: '25px', maxWidth: '250px', backgroundColor: '#fdf4ea'}
    let tagStyleGray = {color: '#d7d7d7', border: '1px solid #d7d7d7', borderRadius: '5px', height: '25px', maxWidth: '250px'}

    return (
        <div style={{display: 'inline-block', margin: '5px', marginLeft: ml, marginRight: mr}}>

            <div style={style === 'green' ? tagStyleGreen : style === 'red' ? tagStyleRed : tagStyleGray} className='d-flex justify-between align-center onHover' onClick={()=>{onClick(uid)}}>
                <span style={{
                    textAlign: 'center',
                    width: '100%',
                    textOverflow: 'ellipsis',
                    wordWrap: 'normal',
                    whiteSpace: 'nowrap',
                    overflowX: 'hidden',
                    padding: '0 5px',
                    ...css
                }}>
                    {name}
                </span>
            </div>

        </div>
    )
}