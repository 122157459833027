import Fuse from 'fuse.js'
import React, { useEffect, useState } from 'react'
import { useAuth } from "../../hooks/auth.hook"
import { useHttp } from "../../hooks/http.hook"
import { MagicSvg } from '../../img/svg'
import { Icon } from "../partials/Icon"
import { architectureExamples } from "./architectureExamples.js"


export const ArchitectureRecommendation = ({ project, getProject, setRec }) => {
    const [projectDescription, setProjectDescription] = useState(project.description)
    const [matchedModules, setMatchedModules] = useState([])


    const { loading, request, error, clearError } = useHttp()
    const { token, login, logout, userId, user, ready } = useAuth()

 
  

    // Настройки Fuse.js
    const fuse = new Fuse(architectureExamples, {
        keys: [
            { name: 'name', weight: 0.8 },                          // Имя модуля
            { name: 'description', weight: 0.8 },                   // Описание модуля
            { name: 'blocks.blocks.name', weight: 0.8 },            // Имя блока
            { name: 'blocks.blocks.description', weight: 0.8 },     // Описание блока
            { name: 'blocks.action.name', weight: 0.8 },            // Имя действия
            { name: 'blocks.action.description', weight: 0.8 },     // Описание действия
            { name: 'combined', weight: 0.8 }                       // Объединённое текстовое поле
        ],
        // includeScore: true,                // Включить оценку совпадения
        threshold: 0.6,                    // Порог для гибкого поиска (0.6 — более гибкий)
        // ignoreLocation: true,              // Игнорировать позицию совпадения
        // useExtendedSearch: true            // Использовать расширенный поиск
    })




    useEffect(() => {
        // Here goes the code you wish to run on mount

        return () => {
            console.log('Unmounted architectureRecommendation')
        }
    }, [])





    // Обработчик изменения описания проекта
    const handleDescriptionChange = (e) => {
        const description = e.target.value
        setProjectDescription(description)

    }

    useEffect(() => {
        const existingNames = new Set(project.blocks.map(block => block.name));
    
        const results = fuse.search(projectDescription);
    
        const filteredResults = results.map(result => result.item).filter(module => {
           
            const blockNames = module.blocks.blocks.map(block => block.name);
            const actionNames = module.blocks.action.map(action => action.name);
            return !blockNames.some(name => existingNames.has(name)) &&
                   !actionNames.some(name => existingNames.has(name));
        });

        setMatchedModules(filteredResults);
    }, [projectDescription, project]);



    console.log("matchedModules", matchedModules)

    const createBlocks = async () => {

        try {

            const requestData = []

            matchedModules[0].blocks.blocks.forEach(async (block) => {
                const blockProiId = -new Date()

                const blockData = {
                    id: blockProiId,
                    projectId: project.id,
                    name: block.name,
                    type: 'Block',
                    description: block.description,
                    nodeId: block.nodeId
                    
                }

                const props = [
                    {
                        id: -new Date(),
                        blockId: blockProiId,
                        name: block.name,
                        type: 'Text',
                        options: [],
                    },
                ]

                const requestDataItem = { block: blockData, props }
                try {
                    const data = await request('/api/block/create', 'POST', requestDataItem, {
                        authorization: 'Bearer ' + token,
                    })
                    requestData.push(data)
                } catch (error) {
                    requestData.push(null)
                }
            })

            matchedModules[0].blocks.action.forEach(async (action) => {

                const blockProiId = -new Date()

                const blockData = {
                    id: blockProiId,
                    projectId: project.id,
                    name: action.name,
                    type: 'Action',
                    description: action.description,
                    nodeId: action.nodeId
                }

                const props = [
                    {
                        id: -new Date(),
                        blockId: blockProiId,
                        name: action.name,
                        type: 'Text',
                        options: [],
                    },
                ]

                const requestDataItem = { block: blockData, props }

                try {
                    const data = await request('/api/block/create', 'POST', requestDataItem, {
                        authorization: 'Bearer ' + token,
                    })
                    getProject(token, project.id)
                    // setRec(true)
                    requestData.push(data)
                } catch (error) {
                    requestData.push(null)
                }
            })

        } catch (e) {
            console.log(e)
        }
    }


   

    return (
        <>
            <div id="modal1" className="modal w-600 modal-fixed-height">
         

                <div className="row clear-row flex-row justify-between mb-0" style={{ borderBottom: '1px solid #eee', width: '100%' }}>
                    <div className="col" style={{ marginTop: '20px', marginLeft: '10px', display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                        <h5 style={{ fontWeight: '300', marginTop: 0, display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                            <span style={{ display: 'flex', alignItems: 'center', gap: '10px', paddingLeft: '17px' }}>
                                <MagicSvg /> Помощник формирования архитектуры
                            </span>
                            <button className="modal-close waves-effect waves-green btn-flat txt-gray" style={{ marginTop: 0, marginLeft: 0 }}>
                                <i style={{ color: '#eaeaea' }} className="fa fa-times" aria-hidden="true" />
                            </button>
                        </h5>
                    </div>
                </div>


                <div className="modal-content" >
                    <div className="flex-col justify-between">
                        {matchedModules.length > 0 ? (
                            <div style={{ border: '1px solid #eee', margin: '0px 5px 0px 5px', padding: '15px 15px 15px 15px', display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                <div style={{ fontWeight: '700', fontSize: '20px' }}>{matchedModules[0].name}</div>

                                <div style={{ fontWeight: '600', fontSize: '13px', color: '#828282' }}>описание</div>
                                <div style={{ fontWeight: '400', fontSize: '16px' }}>{matchedModules[0].description}</div>
                                <div className="d-flex align-center">
                                    <Icon name='box' size='15px' mt='2px' />
                                    <span>Блоки:</span>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                                    {matchedModules[0].blocks.blocks.map((block, i) => (
                                        <div key={i} style={{ fontWeight: '600', fontSize: '16px', background: 'rgba(175, 213, 252, 0.2)', border: '1px solid #ccc', padding: '0px 5px 0px 5px', width: 'fit-content' }}>{block.name}</div>
                                    ))}
                                </div>
                                <div className="d-flex align-center">
                                    <Icon name='flash' size='15px' mt='2px' />
                                    <span>Действия:</span>
                                </div>


                                <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                                    {matchedModules[0].blocks.action.map((action, i) => (
                                        <div key={i} style={{ fontWeight: '600', fontSize: '16px', background: 'rgba(212, 192, 91, 0.2)', border: '1px solid #ccc', padding: '0px 5px 0px 5px', width: 'fit-content' }}>{action.name}</div>
                                    ))}
                                </div>



                            </div>
                        ) : (
                            <div style={{ fontWeight: '700', fontSize: '20px', color: 'gray', textAlign: 'center' }}>
                                Нет подходящих модулей
                            </div>
                        )}
                    </div>
                </div>

                {/*FOOTER SAVE/CANCEL*/}
                <div className="modal-footer mb-1">
                    <div className="d-flex justify-end">
                        <div className="col-auto mr-1">
                            {/*Cancel*/}
                            <button
                                style={{ border: '1px solid grey', color: 'black' }}
                                className="waves-effect waves-dark btn white lighten-1 noUpper modal-close"
                            >
                                Отмена
                            </button>
                        </div>
                        <div className="col-auto mr-2">
                            {/*Save changes*/}
                            <button
                                className="waves-effect waves-light btn blue darken-1 noUpper"
                                onClick={() => { createBlocks() }}
                            >
                                Применить модуль
                            </button>
                        </div>

                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', margin: '0 5px 0 5px', padding: '0 15px 0 15px' }}>
                        <div style={{ fontWeight: '400', fontSize: '14px', color: 'gray', marginLeft: '5px' }}>Описание проекта</div>
                        <textarea
                            onChange={handleDescriptionChange}
                            value={projectDescription}
                            style={{
                                border: '1px solid #eee',
                                borderRadius: '5px',
                                textAlign: 'left',
                                padding: '10px',
                                marginTop: '5px',
                                marginBottom: '10px',
                                // фиксированная ширина
                                height: '130px',  // фиксированная высота
                                resize: 'none'
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
