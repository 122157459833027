import React from 'react'
import {useState, useEffect} from "react";
import {useHttp} from "../hooks/http.hook";
import {useMessage} from "../hooks/message.hook";
import {useContext} from "react";
import {AuthContext} from "../context/AuthContext";
import {useHistory} from "react-router-dom";

export const AuthPage = () => {
    const auth = useContext(AuthContext)
    const history = useHistory()
    const message = useMessage()
    const {loading, request, error, clearError} = useHttp()
    const [form, setForm] = useState({
        email: '',
        password: ''
    })

    useEffect(() => {
        message(error)
        clearError()
    }, [error, message, clearError])

    // Активация input для materialize
    useEffect(() => {
        window.M.updateTextFields()
    }, [])

    const changeHandler = event => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }

    const registerHandler = async () => {
        try {
            const data = await request('/api/auth/register', 'POST', {...form})
            message(data.message)
        } catch (e) {}
    }

    const loginHandler = async () => {
        try {
            const data = await request('/api/auth/login', 'POST', {...form})
            message(data.message)
            auth.login(data.token, data.userId, data.user)
            history.push('/')
        } catch (e) {}
    }

    return (
        <div className="row">
            <div className="col s6 offset-s3">
              <h3 className="center mt-5">Войти по электронной почте</h3>
                {/*<div className="card">*/}
                    <div className="card-content">
                        {/*<span className="card-title">Авторизация</span>*/}
                        <div className="mt-5">
                            <div className="input-field">
                                <input
                                    placeholder="Введите email"
                                    type="text"
                                    id="email"
                                    className=""
                                    name="email"
                                    value={form.email}
                                    autoComplete="off"
                                    onChange={changeHandler}
                                />
                                <label htmlFor="email">Электронная почта</label>
                            </div>

                            <div className="input-field">
                                <input
                                    placeholder="Введите пароль"
                                    type="password"
                                    id="password"
                                    className=""
                                    name="password"
                                    value={form.password}
                                    onChange={changeHandler}
                                />
                                <label htmlFor="password">Пароль</label>
                            </div>
                        </div>
                    </div>
                    <div className="card-action center">
                        <button
                            className="waves-effect waves-light btn blue darken-1 mr-1 noUpper"
                            onClick={loginHandler}
                            disabled={loading}
                        >
                            Войти
                        </button>

                        <button
                            className="waves-effect waves-light btn grey lighten-1 black-text noUpper"
                            onClick={registerHandler}
                            disabled={loading}
                        >
                            Регистрация
                        </button>

                        {/*<button*/}
                            {/*className="btn grey lighten-1 black-text"*/}
                            {/*onClick={() => {history.push('/')}}*/}
                            {/*disabled={loading}*/}
                        {/*>*/}
                            {/*На главную*/}
                        {/*</button>*/}
                    </div>
                {/*</div>*/}
            </div>
        </div>
    )
}