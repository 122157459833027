import React, {useState, useEffect} from 'react'

export const OptsForm = ({p, updateData}) => {
    const [opts, setOpts] = useState([])

    let newOpt = {
        id: - new Date(),
        propertyId: p.id,
        text: '',
        file: 'load code snippet',
        path: null
    }

    // console.log('OptsForm', p)

    useEffect(() => {
        if (!p.options.length && p.type !== 'Text') {
            setOpts([newOpt])
        } else {
            setOpts([...p.options, newOpt])
        }
    }, [p])

    useEffect(() => {
        console.log('OptsForm opts', opts)
    }, [opts])

    const changeOptHandler = (event, id) => {
        let idx = opts.findIndex(el => el.id === id)
        opts[idx][event.target.name] = event.target.value
        setOpts([...opts])
        updateData(p.id, opts.filter(el => el.text))

        //add new opt
        if (opts[opts.length - 1].text) {
            setOpts(prev => [...prev, newOpt])
            let el = document.querySelector('#modal1')
            if (el) {
                el.scrollTop = el.scrollHeight// - el.clientHeight
            }
        }
    }

    const addFileHandler = (event, id) => {
        try {
            let idx = opts.findIndex(el => el.id === id)
            opts[idx]['file'] = event.target.files[0].name
            opts[idx]['path'] = event.target.files[0]
            setOpts([...opts])
            updateData(p.id, opts.filter(el => el.text))
        } catch (e) {
            console.log(e)
        }
    }

    const deleteHandler = id => {
        setOpts([...opts.filter(el => el.id !== id)])
        updateData(p.id, opts.filter(el => el.id !== id).filter(el => el.text))
    }

    if (p.type === 'Text') {
        return <></>
    }

    return (
        <>
        {opts && opts.length ? opts.map(o => {return (
            <React.Fragment key={o.id}>
                <div className="row clear-row flex-row justify-start align-center mb-0">
                    <div className="col s4">
                        <div className="input-field right-align">
                            <span>When</span>
                        </div>
                    </div>

                    <div className="col s4 ml-0">
                        {/*Item*/}
                        <div className="input-field mr-2 my-0 w-100">
                            <input
                                type="text"
                                placeholder="Item name"
                                id={`Iname-${o.id}`}
                                className=""
                                name="text"
                                style={{marginBottom: 0}}
                                value={o.text}
                                onChange={(e) => {changeOptHandler(e, o.id)}}
                            />
                        </div>
                    </div>

                    <div className="col s3 ml-0 pl-0">
                    <span
                        style={{cursor: 'pointer', color: '#0066b9', fontSize: '0.8rem'}}
                        className=""
                        aria-hidden="true"
                        onClick={() => {
                            document.getElementById(`snippet-${o.id}`).click()
                        }}
                    >
                        <i
                            className="fa fa-file"
                            aria-hidden="true"
                            style={{cursor: 'pointer', color: '#0066b9', fontSize: '0.8rem'}}
                        />
                        {/*{o.file}*/}
                        {o.file !== 'load code snippet' && o.file.length > 16 ? `${o.file.slice(0, 6)}..${o.file.lastIndexOf('.') > -1 ? o.file.substr(o.file.lastIndexOf('.'), o.file.length) : ''}` : o.file}
                    </span>

                        <input
                            type='file'
                            id={`snippet-${o.id}`}
                            style={{display: 'none'}}
                            onChange={(e) => {addFileHandler(e, o.id)}}
                        />

                    </div>

                    {/*TRASH*/}
                    <div className="col s1 ml-0 pl-0">
                        {o.text ?
                        <i
                        style={{cursor: 'pointer'}}
                        className="fa fa-trash-o txt-gray"
                        aria-hidden="true"
                        onClick={() => {
                            deleteHandler(o.id)
                        }}
                        /> : <></>}
                    </div>

                </div>
            </React.Fragment>
        )}) : <></>}
        </>
    )
}