import React, {useCallback, useContext, useEffect, useState} from 'react'
import {useHistory, useParams} from "react-router-dom"
import {Header} from "./partials/Header"
import {useHttp} from "../hooks/http.hook"
import {Loader} from "./partials/Loader"
import {useMessage} from "../hooks/message.hook"
import {AuthContext} from "../context/AuthContext"
import {SidebarContext} from "../context/SidebarContext"


export const SearchPage = () => {
    const str = useParams().str
    const { token, logout, user } = useContext(AuthContext)
    const history = useHistory()
    const {loading, request, error, clearError} = useHttp()
    const message = useMessage()
    const [projectsNamed, setProjectsNamed] = useState(null)
    const [projectsWithIssues, setProjectsWithIssues] = useState(null)
    const sBC = useContext(SidebarContext)


    // Активация input для materialize
    useEffect(() => {
        window.M.updateTextFields()
        window.M.FormSelect.init(document.querySelectorAll('select'),  {classes: 'black-text'})
    })

    const getSearch = useCallback(async (token, ss) => {
        try {
            if (token) {
                const data = await request(`/api/search`, 'POST', {searchStr: ss}, {authorization: 'Bearer ' + token})

                console.log('str', ss)
                console.log('projectsNamed', data.projectsNamed)
                console.log('projectsWithIssues', data.projectsWithIssues)
                setProjectsNamed(data.projectsNamed)
                setProjectsWithIssues(data.projectsWithIssues)

            }
        } catch (e) {
            console.log(e)
        }
    }, [request])


    useEffect(() => {
        getSearch(token, str)
    }, [str, getSearch])


    if (loading) {
        return <Loader />
    }

    return (
        <>
            <Header params={{
                title: `Search`,
                btnL: {
                    actionHandler: ()=>{console.log('left')},
                    title: 'btnLeft',
                    display: 'none'
                },
                btnR: {
                    actionHandler: ()=>{},
                    title: '',
                    display: 'none'
                },
                loading
            }}/>

            <div className="row clear-row afterHeader">
                <div className="col-auto">
                    <h5>{str ? `Search results for "${str}"` : 'No search string'}</h5>
                    {/*<p className="txt-gray">Manage your project settings</p>*/}
                </div>
            </div>

        {/*Projects having searchStr into it's name or description*/}
        {projectsNamed && projectsNamed.length ? (
            <>
            <div className="row clear-row">
                <div className="col-auto">
                    <h5>{`Projects having "${str}" into it's name or description`}</h5>
                </div>
            </div>

            <div className="row clear-row flex-row">
                <div className="col s12 pl-0">
                    <table className="highlight">
                        <thead>
                        <tr>
                            <th className="txt-gray ">Имя</th>
                            <th className="txt-gray ">Описание</th>
                        </tr>
                        </thead>
                        <tbody>
                        {projectsNamed && projectsNamed.length ? projectsNamed.map(c => {
                            return(
                                <React.Fragment key={c.id}>
                                    <tr
                                        style={{cursor: 'pointer'}}
                                        onClick={() => {
                                            sBC.toggleMenu('dashboard')
                                            sBC.toggle(false, c.id)
                                            history.push(`/project/${c.id}/dashboard`)
                                        }}
                                    >
                                        <td className="">{c.name || '-'}</td>
                                        <td className="">{c.description || '-'}</td>
                                    </tr>

                                </React.Fragment>
                            )
                        }) : (<></>)}
                        </tbody>
                    </table>
                </div>
            </div>
            </>
        ) : (<></>)}

        {/*Projects having Epic with name like searchStr*/}
        {projectsWithIssues && projectsWithIssues.length ? (
            <>
            <div className="row clear-row">
                <div className="col-auto">
                    <h5>{`Projects having "${str}" into it's Epics names`}</h5>
                </div>
            </div>

            <div className="row clear-row flex-row">
                <div className="col s12 pl-0">
                    <table className="highlight">
                        <thead>
                        <tr>
                            <th className="txt-gray ">Имя</th>
                            <th className="txt-gray ">Описание</th>
                        </tr>
                        </thead>
                        <tbody>
                        {projectsWithIssues && projectsWithIssues.length ? projectsWithIssues.map(c => {
                            return(
                                <React.Fragment key={c.id}>
                                    <tr
                                        style={{cursor: 'pointer'}}
                                        onClick={() => {
                                            sBC.toggleMenu('dashboard')
                                            sBC.toggle(false, c.id)
                                            history.push(`/project/${c.id}/dashboard`)
                                        }}
                                    >
                                        <td className="">{c.name || '-'}</td>
                                        <td className="">{c.description || '-'}</td>
                                    </tr>

                                </React.Fragment>
                            )
                        }) : (<></>)}
                        </tbody>
                    </table>
                </div>
            </div>
            </>
        ) : (<></>)}


        </>
    )
}