import React, {useContext, useCallback, useEffect, useState} from 'react'
import {Link, useHistory, useParams} from "react-router-dom"
import {AuthContext} from "../../context/AuthContext"
import {useHttp} from "../../hooks/http.hook"
import {useMessage} from "../../hooks/message.hook"
import {Skill} from "../partials/Skill"
import {Tag} from "../partials/Tag"
import {Header} from "../partials/Header"
import {Loader} from "../partials/Loader"
import {SidebarContext} from "../../context/SidebarContext"
import {CircleProgress} from "../partials/CircleProgress"
import { Bar } from 'react-chartjs-2'


export const DashboardPage = (props) => {
    const id = useParams().id
    const { token, user, logout, login } = useContext(AuthContext)
    const {loading, request, error, clearError} = useHttp()
    const message = useMessage()
    const history = useHistory()
    const sBC = useContext(SidebarContext)

    const [project, setProject] = useState(null)
    const [connected, setConnected] = useState(false)
    const [filtered, setFiltered] = useState([])
    const [jiraStatuses, setJiraStatuses] = useState([])
    const [jiraEpics, setJiraEpics] = useState([])

    const [epic, setEpic] = useState(null)
    const [chartData, setChartData] = useState({
        labels: [], //Dates from create epic to target date
        datasets: [
            {
                type: 'line',
                label: 'объем',
                data: [], // tasks finished (on date ^^^), check by target date (duedate)
                fill: false,
                backgroundColor: '#90959d',
                borderColor: '#90959d',
            },
            {
                type: 'line',
                label: 'прогресс',
                data: [], // tasks really finished (on date ^^^), check by status == ready &&  fields.updated
                fill: false,
                backgroundColor: '#6e7ad7',
                borderColor: '#6e7ad7',
            },
            {
                type: 'bubble',
                label: 'цель',
                radius: 7,
                data: [], // tasks really finished (on date ^^^), check by status == ready &&  fields.updated
                fill: false,
                backgroundColor: 'red',
                borderColor: 'red',
            },
        ],
    })

    const options = {
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
    }

    useEffect(() => {
        message(error)
        clearError()
    }, [error, message, clearError, logout, history])

    const setEpicStatus = (statuses, epic) => {
        if (epic.tasks.length) {
            if (epic.tasks.filter(ts => ts.status.name === 'В работе').length > 0) {
                epic.status = {...epic.tasks.filter(ts => ts.status.name === 'В работе')[0].status}
                return
            }

            if (epic.tasks.filter(ts => ts.status.name === 'Готово').length === epic.tasks.length) {
                epic.status = {...epic.tasks.filter(ts => ts.status.name === 'Готово')[0].status}
            }
        }
    }

    const getProject = useCallback(async (token, id) => {
        try {
            if (token && id) {
                const data = await request(`/api/project/roadmap/${id}`, 'GET', null, {authorization: 'Bearer ' + token})
                setProject(data.project)

                console.log('project', data.project)

                // Get jira labels aka Skills
                if (data.project.jiraPass) {
                    const sk = await request(`/api/jira/roadmap`, 'POST', {
                        jiraName: data.project.jiraName,
                        jiraUrl: data.project.jiraUrl,
                        jiraPass: data.project.jiraPass,
                        prjName: data.project.jiraProject,
                        HRprjName: data.project.jiraHRProject
                    }, {authorization: 'Bearer ' + token})
                    if (sk.connected) {
                        setConnected(true)
                        setJiraStatuses(sk.statuses)

                        // check epic status: all in planning = planning, have one in work = in work, all finished = finished
                        // sk.epics.forEach(e => setEpicStatus(sk.statuses, e))
                        for (let i = 0; i < sk.epics.length; i++) {
                            setEpicStatus(sk.statuses, sk.epics[i])
                            let k = await request(`/api/roadmap/getK/${sk.epics[i].id}`, 'GET', null, {authorization: 'Bearer ' + token})
                            sk.epics[i].k = k.k
                        }

                        setJiraEpics(sk.epics)
                        setFiltered(sk.epics)

                        console.log('Epics', sk.epics)
                        // console.log('Statuses', sk.statuses)
                    }
                } else {
                    setConnected(false)
                    setJiraStatuses([])
                    setJiraEpics([])
                    setFiltered([])
                }

            }
        } catch (e) {
            console.log(e)
        }
    }, [request])

    const formatDateUS = (d) => new Date(d).toLocaleDateString('en-US', {month: 'short', day: 'numeric'})

    const formatDateYY = (date) => {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear()

        if (month.length < 2)
            month = '0' + month
        if (day.length < 2)
            day = '0' + day

        return [year, month, day].join('-')
    }

    const epicDueDate = (selEpic) => {
        return selEpic && selEpic.duedate ? selEpic.duedate :
        selEpic && selEpic.tasks.length && selEpic.tasks.reduce((prev, curr) => {
                return new Date(prev.duedate) > new Date(curr.duedate) ? prev : curr
            }) ? selEpic.tasks.reduce((prev, curr) => {
                    return new Date(prev.duedate) > new Date(curr.duedate) ? prev : curr
                }).duedate
                : null
    }

    const getDaysArray = (start, end) => {
        let arrUS=[]
        let arrYY=[]
        for(let dt=start; dt<=end; dt.setDate(dt.getDate()+1)){
            arrYY.push(formatDateYY(dt))
            arrUS.push(formatDateUS(dt))
        }
        // arr.push(formatDateUS(new Date(end.setDate(end.getDate()+1))))
        return {arrYY, arrUS}
    };

    const epicSelected = useCallback((epicId) => {
        let selEpic = jiraEpics.find(ep => ep.id === epicId)
        setEpic(selEpic)

        const chart = {
            labels: [],
            datasets: [
                {
                    type: 'line',
                    label: 'scope',
                    data: [], // tasks finished (on date ^^^), check by target date (duedate)
                    fill: false,
                    backgroundColor: '#90959d',
                    borderColor: '#90959d',
                },
                {
                    type: 'line',
                    label: 'progress',
                    data: [], // tasks really finished (on date ^^^), check by status == ready &&  fields.updated
                    fill: false,
                    backgroundColor: '#6e7ad7',
                    borderColor: '#6e7ad7',
                },
                {
                    type: 'bubble',
                    radius: 7,
                    label: 'target',
                    data: [], // tasks really finished (on date ^^^), check by status == ready &&  fields.updated
                    fill: false,
                    backgroundColor: 'red',
                    borderColor: 'red',
                },
            ],
        }
        // here set chartData

        // Epic create date
        let start = formatDateYY(new Date(selEpic.fields.created)) // string yyyy-mm-dd
        console.log('created: ', start)
        console.log('created: ', formatDateUS(start))

        // Epic duedate or max of tasks duedate (also set Red POINT -> all tasks count)
        let end = epicDueDate(selEpic) // string string yyyy-mm-dd
        if (!end) {
            // there no one target day
            end = new Date()
        }
        console.log('Target date: ', end)
        console.log('Target date: ', formatDateUS(end))

        // ^^^ -> chartData.labels: [from start date to end date]
        let labels = getDaysArray(new Date(start), new Date(end))
        console.log('Dates between', labels)
        chart.labels = [...labels.arrUS]

        // max yAxis
        let maxTasks = selEpic.tasks.length
        // and set Red point to max duedate and max tasks
        chart.datasets[2].data = [{x: labels.arrUS[labels.arrUS.length-1], y: maxTasks}]

        // for each date need count of finished tasks -> blue line
        // for each date need count of duedate tasks -> gary line
        let dueTasks = 0
        let readyTasks = 0
        labels.arrYY.forEach(date => {
            // here cycle by tasks to check task.duedate and fields.resolutiondate = finished date!
            // count of tasks having duedate === date on x axis
            dueTasks += selEpic.tasks.filter(t => t.duedate === date).length
            chart.datasets[0].data.push(dueTasks)

            // count of tasks having resolutiondate === date on x axis
            readyTasks += selEpic.tasks.filter(t => formatDateYY(new Date(t.fields.resolutiondate)) === date).length
            if (new Date(date) <= new Date()) chart.datasets[1].data.push(readyTasks)
        })

        setChartData(chart)
    })

    useEffect(() => {
        getProject(token, id)
    }, [getProject, id, token])

    useEffect(() => {
        console.log('Curr Epic', epic)
    }, [epic])

    if (!project || loading) {
        return <Loader />
    }


return (
    <>
    <Header params={{
        title: `Dashboard`,
        subTitle: '',
        bk: [
            {
                title: project ? project.name : '',
                // actionHandler: () => {history.goBack()}
                actionHandler: () => {}
            },
            {
                title: `Информационная панель`,
                actionHandler: () => {}
            },
        ],
        btnL: {
            actionHandler: ()=>{},
            title: '',
            display: 'none'
        },
        btnR: {
            actionHandler: () => {},
            title: '',
            display: 'none'
        },
        loading
    }}/>

    <div className="row clear-row afterHeader">

        {project && !connected ?
            <div style={{backgroundColor: '#fff4cd', color: '#866607', padding: '10px', textAlign: 'justify'}}>
                <span style={{fontWeight: 600}}>Важно!</span> У вас не настроена интеграция с Jira,
                поэтому функция прогнозирование ресурсов и связка тегов пока недоступны.
                Вы можете настроить интеграцию <Link to={`#`} onClick={() => {
                sBC.toggleMenu('integration')
                sBC.toggle(false, project.id)
                history.push(`/project/${project.id}/integrations`)
            }} >здесь.</Link>
            </div> :
            <>

            <div className="col s7" style={{minHeight: window.innerHeight - 120, marginBottom: 0}}>
                <h5>Этапы в процессе</h5>
                {/*<table className="highlight">*/}

                <table className="highlight">
                    <thead>
                    <tr>
                        <th className="txt-gray">Название этапа</th>
                        <th className="txt-gray">Прогресс</th>
                        <th className="txt-gray" style={{width: '150px'}}>Дата выполнения</th>
                    </tr>
                    </thead>

                    <tbody>
                        {filtered.length ? filtered.map(e => { return (
                            <tr
                                key={e.id}
                                style={{border: 'none', cursor: 'pointer'}}
                                onClick={() => {
                                    // console.log('Show detail for epic:', e.id)
                                    epicSelected(e.id)
                                    // setEpic(jiraEpics.find(ep => ep.id === e.id))
                                }}
                            >
                                <td style={{padding: '5px'}}>
                                    {e.summary}
                                </td>
                                <td style={{padding: '5px'}}>
                                    {e.aggregateprogress.percent ? `${Math.floor(e.aggregateprogress.percent / e.k)}%` : '0%'}
                                </td>
                                <td style={{padding: '5px'}}>
                                    {epicDueDate(e) ? formatDateUS(epicDueDate(e)) : '-'}
                                    {/*{e.duedate ? e.duedate :*/}
                                        {/*e.tasks.length && e.tasks.reduce((prev, curr) => {*/}
                                            {/*return new Date(prev.duedate) > new Date(curr.duedate) ? prev : curr*/}
                                        {/*}) ?*/}
                                            {/*e.tasks.length && e.tasks.reduce((prev, curr) => {*/}
                                                {/*return new Date(prev.duedate) > new Date(curr.duedate) ? prev : curr*/}
                                        {/*}).duedate : '-'*/}
                                    {/*}*/}
                                </td>
                            </tr>
                        )}) : <></>}
                    </tbody>

                </table>
            </div>

            <div className="col s5" style={{borderLeft: '1px solid #eff1f4'}}>
                <p className="txt-gray" style={{margin: '1.0933333333rem 0 .656rem 0'}}>Детали этапа</p>
                <p>
                    <i
                        style={{cursor: 'pointer'}}
                        className="fa fa-flag pr-1 txt-gray"
                        aria-hidden="true"
                    />
                    <span style={{fontSize: '18px', fontWeight: 400}}>{epic ? epic.summary : '-'}</span>
                </p>

                <div style={{borderBottom: '1px solid #e0e0e0', margin: '15px auto', width: '100%'}} />

                {/*Done % | tasks | days to target date*/}
                <div className="d-flex justify-start align-center mb-2">
                    <div className="d-flex justify-around align-center">
                        <CircleProgress color='#6e7ad7' size='70' showText={false} strokeWidth='5px' progress={epic ? epic.aggregateprogress.percent ? Math.floor(epic.aggregateprogress.percent / epic.k) : '0' : '0'}/>
                        <div>
                            <p style={{fontSize: '20px', textAlign: 'center'}}>{epic ? epic.aggregateprogress.percent ? Math.floor(epic.aggregateprogress.percent / epic.k) : '0' : '0'}%</p>
                            <p style={{textAlign: 'left'}} className='txt-gray'>готово</p>
                        </div>
                    </div>

                    <div className="pl-2">
                        <p style={{fontSize: '20px'}}>
                            {epic && epic.tasks.length ?
                                `${epic.tasks.filter(ts => ts.status.name === 'Готово').length} / ${epic.tasks.length}` :
                                `0 / 0`
                            }
                        </p>
                        <p className='txt-gray'>задач</p>
                    </div>

                    <div className="pl-2">
                        <p style={{fontSize: '20px'}}>
                            {epic && epic.duedate ?
                                `${Math.ceil(Math.abs(new Date(epic.duedate).getTime() - new Date().getTime()) / (1000 * 3600 * 24))} дней` :
                                epic && epic.tasks.length && epic.tasks.reduce((prev, curr) => {
                                    return new Date(prev.duedate) > new Date(curr.duedate) ? prev : curr
                                }) ? `${Math.ceil(Math.abs(new Date(epic.tasks.reduce((prev, curr) => {
                                        return new Date(prev.duedate) > new Date(curr.duedate) ? prev : curr
                                    }).duedate).getTime() - new Date().getTime()) / (1000 * 3600 * 24))} дней`
                                    : '? дней'
                            }
                        </p>
                        <p className='txt-gray'>до даты выполнения</p>
                    </div>
                </div>

                {/*Chart*/}
                <div className="row clear-row justify-between align-center w-100">
                    <div className="col s12 w-100">
                        <Bar data={chartData} options={options} />
                    </div>
                </div>

                {/*Progress by teammates*/}
                <div className="row clear-row justify-between align-center">
                    <div className="col s12">
                        <p className="txt-gray my-1">Прогресс участников команды</p>
                        {/*Teammates*/}
                        {project.teams.length && epic ? project.teams.filter(el => el.epicTeams.filter(e => String(e.epicId) === epic.id).length).map(el => {return(
                            <React.Fragment key={el.id}>
                                <table>
                                    <tbody className='clearTable'>
                                    <tr>
                                        <td className="center" style={{maxWidth: '20px'}}>
                                            <div style={{width: '10px', height: '10px', borderRadius: '50%', backgroundColor: '#d4c05b', margin: 'auto'}} />
                                        </td>
                                        <td>
                                            {/*{el.name ? el.user.name : el.user.email}*/}
                                            {el.user.email}
                                        </td>
                                        <td className='w-100' style={{textAlign: 'right'}}>
                                            {epic && epic.tasks.length ? epic.tasks.filter(t => t.status.name === 'Готово' && t.fields.assignee && t.fields.assignee.emailAddress === el.jiraUser).length : 0} / {epic && epic.tasks.length ? epic.tasks.filter(t => t.fields.assignee && t.fields.assignee.emailAddress === el.jiraUser).length : 0} tasks
                                            {/*5 / 12 tasks*/}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </React.Fragment>
                        )}) : <span>Нет команды</span>}
                    </div>
                </div>

            </div>

            </>
        }

    </div>
    </>
)

}