import {useCallback, useState} from 'react'

export const useToggleSidebar = () => {
    const [isProject, setIsProject] = useState(false)
    const [id, setId] = useState(null)
    const [menuId, setMenuId] = useState(null)

    const toggle = useCallback((isP = false, pid = null) => {
        setIsProject(isP)
        setId(pid)
    }, [])

    const toggleMenu = useCallback((mId = null) => {
        setMenuId(mId)
    }, [])

    return {isProject, toggle, id, menuId, toggleMenu}
}