import React from 'react'
import {Loader} from "./Loader"
import {Link} from "react-router-dom"

export const NoTable = ({params}) => {

    if (!params) {
        return <Loader />
    }

    return (
        <div className="row clear-row flex-row justify-around align-center w-100" style={{height: 'calc(100vh - 400px)'}}>
            <div className="col-auto w-100 center">
                <i className="fa fa-folder-open-o txt-gray" aria-hidden="true" style={{fontSize: '7rem', opacity: 0.5}} />
                <p style={{fontSize: '1.2rem'}} className="txt-gray center">{params ? params.title : ''}</p>
                {params && params.link ? (
                    <Link to={params && params.link ? params.link : ''}>{params.linkTitle}</Link>
                ) : (<></>)}
            </div>
        </div>
    )
}