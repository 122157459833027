import React, {useEffect, useState} from 'react'
import 'materialize-css'
import {useRoutes} from "./routes"
import {BrowserRouter as Router} from "react-router-dom"
import {Sidebar} from "./pages/partials/Sidebar"
import {useAuth} from "./hooks/auth.hook"
import {AuthContext} from "./context/AuthContext"
import {Loader} from "./pages/partials/Loader"
import {SidebarContext} from "./context/SidebarContext"
import {useToggleSidebar} from "./hooks/toggleSidebar.hook"
import {ProjectSidebar} from "./pages/partials/ProjectSidebar"

function App() {
    const {token, login, logout, userId, user, ready} = useAuth()
    const isAuthenticated = !!token
    const routes = useRoutes(isAuthenticated)

    const {toggle, isProject, id, menuId, toggleMenu} = useToggleSidebar()

    const [loading, setLoading ] = useState(true)
    useEffect(() => {
        setLoading(false)
    }, [setLoading])

    if(!ready || loading) {
        return <Loader />
    }

    return (
        <AuthContext.Provider value={{ token, login, logout, userId, user, isAuthenticated }}>
            <SidebarContext.Provider value={{isProject, toggle, id, menuId, toggleMenu}}>
            <Router>
                {isAuthenticated ?
                    <>
                        <div className="container-fluid" style={{overflowX: 'auto', height: '100vh'}}>
                            <div className="row clear-row">
                                <div className="col s2" style={{borderRight: '1px solid rgba(0, 0, 0, 0.1)', height: '100vh', position: 'fixed', overflowY: 'auto', minWidth: '281px', width: '281px', backgroundColor: '#fff', zIndex: 200}}>
                                    {!isProject ? (<Sidebar  />) : (<ProjectSidebar/>)}
                                </div>

                                {/*<div className="col s10" style={{marginLeft: '240px', overflowX: 'auto', paddingTop: '65px', marginBottom: '2rem'}}>*/}
                                {/*<div className="col s10" style={{marginLeft: '240px', marginBottom: '2rem'}}>*/}
                                <div className="col s10" style={{marginLeft: '240px', marginBottom: 0}}>
                                    {/*<div className="container-fluid ml-2 mr-2">*/}
                                    {/*<div className="container" style={{marginBottom: '8rem'}}>*/}
                                    <div className="container" style={{marginBottom: 0}}>
                                        {routes}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                :
                    <div className="container">
                        {routes}
                    </div>
                }
            </Router>
            </SidebarContext.Provider>
        </AuthContext.Provider>
    )
}

export default App