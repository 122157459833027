import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from "../../context/AuthContext"
import { useHttp } from "../../hooks/http.hook"
import { OptsForm } from "./OptsForm"
// import {useHistory} from "react-router-dom"
import { useMessage } from "../../hooks/message.hook"
// import {Loader} from "../partials/Loader"

export const BlockForm = ({ options, updateOnChange }) => {
    const { token, user, logout, login } = useContext(AuthContext)
    const { loading, request, error, clearError, fileUpload } = useHttp()
    const message = useMessage()
    // const history = useHistory()
    const [props, setProps] = useState([])
    const [block, setBlock] = useState({
        id: - new Date(),
        projectId: options.project.id,
        name: '',
        type: 'Block',
        description: ''
    })

    let newBlock = {
        id: - new Date(),
        projectId: options.project.id,
        name: '',
        type: 'Block',
        description: ''
    }

    let newProp = {
        id: - new Date(),
        blockId: options.blockId,
        name: '',
        type: 'Text',
        // selectedId: 0,
        options: []
    }

    // console.log('options in modal', options)
    useEffect(() => {
        message(error)
        clearError()
    }, [error, message, clearError, logout])

    useEffect(() => {
        if (options.blockId < 0) {
            setProps([newProp])
            setBlock({ ...newBlock })
        } else {
            setBlock({ ...options.project.blocks.find(b => b.id === options.blockId) })
            setProps([...options.project.blocks.find(b => b.id === options.blockId).properties])
        }
    }, [options])

    useEffect(() => {
        // console.log('props', props)
        // console.log('block', block)
        if (document.querySelectorAll('select')) window.M.FormSelect.init(document.querySelectorAll('select'))
    }, [props])

    const addPropertyHandle = () => {
        if (validate()) {
            setProps(prev => { return [...prev, newProp] })
            let el = document.querySelector('#modal1')
            if (el) {
                el.scrollTop = el.scrollHeight// - el.clientHeight
            }
        }
    }

    const changePropHandler = (event, id) => {
        let idx = props.findIndex(el => el.id === id)
        props[idx][event.target.name] = event.target.value
        setProps([...props])
    }

    const deletePropHandler = (id) => {
        setProps([...props.filter(p => p.id !== id)])
    }

    const changeBlockHandler = event => {
        setBlock({ ...block, [event.target.name]: event.target.value })
    }

    const updateOpts = (propId, data) => {
        let idx = props.findIndex(el => el.id === propId)
        props[idx]['options'] = data
        setProps([...props])
    }

    const validate = () => {
        let ok = true

        if (!block.name) {
            document.getElementById('name').classList.add('invalid')
            ok = false
        }

        props.forEach(p => {
            if (!p.name) {
                document.getElementById(`Pname-${p.id}`).classList.add('invalid')
                ok = false
            }
        })

        return ok
    }

    const updateHandler = async () => {
        if (!validate()) return

        if (block.id < 0) {
            // create
            try {
                const data = await request('/api/block/create', 'POST', { block, props }, { authorization: 'Bearer ' + token })

                // console.log('data', data)
                let blockId = data.blockId

                // foreach file on options save
                if (props.length) {
                    for (let i = 0; i < props.length; i++) {
                        let propId = data.props.find(el => el.start === props[i].id).id
                        console.log('propId', propId)

                        if (props[i].options.length) {
                            for (let j = 0; j < props[i].options.length; j++) {
                                let optId = data.opts.find(el => el.start === props[i].options[j].id).id
                                console.log('optId', optId)

                                if (props[i].options[j].path instanceof File) {
                                    // save file
                                    const fd = new FormData()
                                    fd.append('snippet', props[i].options[j].path)

                                    const fl = await fileUpload('/api/block/upload', 'POST', fd, { authorization: 'Bearer ' + token, blockId, propId, optId })
                                }
                            }
                        }
                    }
                }

                message(data.message)

            } catch (e) {
                console.log(e)
            }

        } else {
            // upadte
            try {
                // update block
                const data = await request(`/api/block/${block.id}`, 'PUT', { block, props }, { authorization: 'Bearer ' + token })

                // check new files and save, and delete old (if changed or removed)
                let blockId = data.blockId

                // foreach file on options save
                if (props.length) {
                    for (let i = 0; i < props.length; i++) {
                        let propId = data.props.find(el => el.start === props[i].id).id
                        console.log('propId', propId)

                        if (props[i].options.length) {
                            for (let j = 0; j < props[i].options.length; j++) {
                                let optId = data.opts.find(el => el.start === props[i].options[j].id).id
                                console.log('optId', optId)

                                if (props[i].options[j].path instanceof File) {
                                    // save file
                                    const fd = new FormData()
                                    fd.append('snippet', props[i].options[j].path)

                                    const fl = await fileUpload('/api/block/upload', 'POST', fd, { authorization: 'Bearer ' + token, blockId, propId, optId })
                                }
                            }
                        }
                    }
                }

                message(data.message)
            } catch (e) {
                console.log(e)
            }
        }

        updateOnChange()
    }

    return (
        <>
            <div id="modal1" className="modal w-600" style={{ overflowY: 'auto', overflowX: 'hidden' }}>

                <div className="modal-content">
                    <div className="row clear-row flex-row justify-between mb-0" style={{ borderBottom: '1px solid #eee' }}>
                        <div className="col">
                            <h5 style={{ fontWeight: '600', marginTop: 0 }}>Создание/Редактирование блока</h5>
                        </div>
                        <div className="col" >
                            {/*<button className="modal-close waves-effect waves-green btn-flat txt-gray" style={{paddingRight: 0, marginTop: '-15px'}}><i className="fa fa-times" aria-hidden="true" /></button>*/}
                            <button className="modal-close waves-effect waves-green btn-flat txt-gray" style={{ paddingRight: 0, marginTop: '-5px' }}><i style={{ color: '#eaeaea' }} className="fa fa-times" aria-hidden="true" /></button>
                        </div>
                    </div>
                    {/*<p className="txt-gray mt-0 mb-1">Создание/Редактирование блока</p>*/}

                    {/*BLOCK*/}
                    <div className="row clear-row flex-row justify-between mb-0 mt-1">
                        <div className="col s6">
                            {/*Name*/}
                            <div className="input-field mr-2 mb-0">
                                <input
                                    type="text"
                                    id="name"
                                    // className="validate"
                                    name="name"
                                    style={{ marginBottom: 0 }}
                                    value={block.name}
                                    onChange={changeBlockHandler}
                                />
                                <label htmlFor="name">Название блока*</label>
                            </div>

                            {/*Description*/}
                            <div className="input-field mr-2">
                                <textarea
                                    id="description"
                                    name="description"
                                    value={block.description}
                                    onChange={changeBlockHandler}
                                    className="materialize-textarea" />
                                <label htmlFor="description">Описание</label>
                            </div>
                        </div>

                        <div className="col s6">
                            {/*Type*/}
                            <div className="input-field">
                                <select
                                    id="type"
                                    name="type"
                                    value={block.type}
                                    onChange={changeBlockHandler}
                                >
                                    {/*{ projects.length ? projects.map(p => <option key={p.id} value={p.key}>{p.name}</option>) : (<option value="0">-</option>)}*/}
                                    <option value="Block">Блок</option>
                                    <option value="Action">Действие</option>
                                </select>
                                <label htmlFor="type">Тип*</label>
                            </div>
                        </div>
                    </div>


                    <div className="row clear-row flex-row justify-start mb-1">
                        <div className="">
                            <p className="txt-gray" style={{ fontSize: '1.2rem', textDecoration: 'underline' }}>Характеристики</p>
                        </div>
                    </div>

                    {/*PROPERTIES*/}
                    {/*todo:  iterable make component*/}
                    {props && props.length ? props.map(p => {
                        return (
                            <React.Fragment key={p.id}>
                                <div className="row clear-row flex-row justify-start align-center mb-0">
                                    <div className="col s4 ml-0">
                                        {/*Name*/}
                                        <div className="input-field mr-2 my-0 w-100">
                                            <input
                                                type="text"
                                                placeholder="название"
                                                id={`Pname-${p.id}`}
                                                // className="validate"
                                                name="name"
                                                style={{ marginBottom: 0 }}
                                                value={p.name}
                                                onChange={(e) => { changePropHandler(e, p.id) }}
                                            />
                                            {/*<label htmlFor={`Pname-${p.id}`}>Prop name</label>*/}
                                        </div>
                                    </div>

                                    <div className="col s4 ml-0">
                                        {/*Type*/}
                                        {/*todo: if select -> add options component (iterable)*/}
                                        <div className="input-field my-0 mb0-underlined">
                                            <select
                                                id={`Ptype-${p.id}`}
                                                name="type"
                                                value={p.type}
                                                onChange={(e) => { changePropHandler(e, p.id) }}
                                            >
                                                {/*{ projects.length ? projects.map(p => <option key={p.id} value={p.key}>{p.name}</option>) : (<option value="0">-</option>)}*/}
                                                <option value="Select">Выбрать (раскрывающийся список)</option>
                                                <option value="Text">Текст</option>
                                            </select>
                                            {/*<label htmlFor="type">Type*</label>*/}
                                        </div>
                                    </div>

                                    {/*LOAD SNIPPET*/} {/* Move to opts component */}
                                    {/*<div className="col s3 ml-0" style={{display: p.type === 'Text' ? 'none' : 'block'}}>*/}
                                    {/*<span*/}
                                    {/*style={{cursor: 'pointer', color: '#0066b9', fontSize: '0.8rem'}}*/}
                                    {/*className=""*/}
                                    {/*aria-hidden="true"*/}
                                    {/*// onClick={() => {deleteHandler(c.id)}}*/}
                                    {/*>*/}
                                    {/*load code snippet*/}
                                    {/*</span>*/}
                                    {/*<input*/}
                                    {/*type='file'*/}
                                    {/*id="snippet"*/}
                                    {/*// ref={inputFile}*/}
                                    {/*style={{display: 'none'}}*/}
                                    {/*// value={form.img}*/}
                                    {/*// onChange={imgChangeHandler}*/}
                                    {/*// accept=".jpg"*/}
                                    {/*/>*/}
                                    {/*</div>*/}

                                    {/*TRASH*/}
                                    <div className="col s1 ml-0 pl-0">
                                        <i
                                            style={{ cursor: 'pointer' }}
                                            className="fa fa-trash-o txt-gray"
                                            aria-hidden="true"
                                            onClick={() => { deletePropHandler(p.id) }}
                                        />
                                    </div>
                                </div>

                                {/*OPTIONS*/}
                                <OptsForm p={p} updateData={updateOpts} />

                            </React.Fragment>
                        )
                    }
                    ) : <></>}

                    <div className="row clear-row flex-row justify-start mb-0 mt-1">
                        <div className="col s4 ml-0">
                            {/*ADD PROPERTY BTN*/}
                            <button
                                style={{ border: '1px solid grey', color: 'black' }}
                                className="waves-effect waves-dark btn white lighten-1 text-lower"
                                onClick={addPropertyHandle}
                            >
                                + Добавить свойство
                            </button>
                        </div>
                    </div>

                </div>

                {/*FOOTER SAVE/CANCEL*/}
                <div className="modal-footer overflowHidden mb-1">
                    <div className="d-flex justify-end">
                        <div className="col-auto mr-1">
                            {/*Cancel*/}
                            <button
                                style={{ border: '1px solid grey', color: 'black' }}
                                className="waves-effect waves-dark btn white lighten-1 noUpper modal-close"
                            // onClick={connectHandler}
                            >
                                Отмена
                            </button>
                        </div>

                        <div className="col-auto mr-1">
                            {/*Save changes*/}
                            <button
                                className="waves-effect waves-light btn blue darken-1 noUpper"
                                onClick={updateHandler}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}