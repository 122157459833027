import React, { useState } from 'react'

export const MagicSvg = () => {
  const [hovered, setHovered] = useState(false)

  const handleMouseOver = () => {
    setHovered(true)
  }

  const handleMouseOut = () => {
    setHovered(false)
  }

  return (
    <svg
      className="magic-svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      style={{
        cursor: 'pointer', // Изменение курсора при наведении (по желанию)
      }}
    >
      <path
        d="M12 3L10.088 8.813C9.99015 9.11051 9.82379 9.38088 9.60234 9.60234C9.38088 9.82379 9.11051 9.99015 8.813 10.088L3 12L8.813 13.912C9.11051 14.0099 9.38088 14.1762 9.60234 14.3977C9.82379 14.6191 9.99015 14.8895 10.088 15.187L12 21L13.912 15.187C14.0099 14.8895 14.1762 14.6191 14.3977 14.3977C14.6191 14.1762 14.8895 14.0099 15.187 13.912L21 12L15.187 10.088C14.8895 9.99015 14.6191 9.82379 14.3977 9.60234C14.1762 9.38088 14.0099 9.11051 13.912 8.813L12 3Z"
        stroke={hovered ? '#ffd500' : '#D4C05B'}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <animateTransform
          attributeName="transform"
          type="scale"
          values="1;1.07;1"
          dur="1.7s"
          repeatCount="indefinite"
        />
      </path>
      <path
        d="M5 3V7"
        stroke={hovered ? '#ffd500' : '#D4C05B'}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      >

        <animateTransform
          attributeName="transform"
          type="translate"
          values="0 -1;0 1;0 -1"
          dur="2s"
          repeatCount="indefinite"
        />
      </path>
      <path
        d="M19 17V21"
        stroke={hovered ? '#ffd500' : '#D4C05B'}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      >

        <animateTransform
          attributeName="transform"
          type="translate"
          values="0 -1;0 1;0 -1"
          dur="2s"
          repeatCount="indefinite"
        />

      </path>
      <path
        d="M3 5H7"
        stroke={hovered ? '#ffd500' : '#D4C05B'}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      >


        <animateTransform
          attributeName="transform"
          type="translate"
          values="0 -1;0 1;0 -1"
          dur="2s"
          repeatCount="indefinite"
        />


      </path>
      <path
        d="M17 19H21"
        stroke={hovered ? '#ffd500' : '#D4C05B'}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        {/* Анимация пульсации */}

        <animateTransform
          attributeName="transform"
          type="translate"
          values="0 -1;0 1;0 -1"
          dur="2s"
          repeatCount="indefinite"
        />

      </path>
    </svg>
  )
}
