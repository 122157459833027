import React from 'react'

export const Icon = ({name, size='30px', mt=0, mr='1rem'}) => {
    return (
        <img
            style={{overflow: 'hidden', width: size, height: size, marginRight: mr, marginTop: mt}}
            src={`/assets/icons/${name}.png`}
            alt=""
        />
    )
}