import React, {useContext, useCallback, useEffect} from 'react'
import {useHistory} from "react-router-dom"
import {AuthContext} from "../context/AuthContext"
import {useHttp} from "../hooks/http.hook"
import {useMessage} from "../hooks/message.hook"
import {Skill} from "./partials/Skill"
import {Tag} from "./partials/Tag"
import {NoTable} from "./partials/NoTable"

export const MainPage = (props) => {
    const { token, user, logout, login } = useContext(AuthContext)
    const {loading, request, error, clearError} = useHttp()
    const message = useMessage()
    const history = useHistory()

    useEffect(() => {
        message(error)
        clearError()
    }, [error, message, clearError, logout, history])

    const testJira = useCallback(async () => {
        try {
            const data = await request(`/api/jira`, 'GET', null, {authorization: 'Bearer ' + token})
            message(data.message)
        } catch (e) {
            console.log(e)
        }
    }, [request])

    const onDeleteSkill = (skid) => {
        console.log('get in main', skid)
    }

return (
    <div className="row h-100p mt-5">
        <div className="col s12 h-100p d-flex align-center mt-3">
            <NoTable params={{ title: `Выберите существующий проект или создайте новый проект`, link: '/project/create', linkTitle: 'Создать проект'  }} />
        </div>
        {/*<div className="col-auto">*/}
            {/*<h1>Main</h1>*/}
            {/*/!*<Skill name='Test' level='10' css={{border: '1px solid red'}}/>*!/*/}
            {/*<Skill name='Very long long long long skill name' level='10'/>*/}
            {/*<Skill name='Java' level='10'/>*/}
            {/*<Skill name='DB' level='5' onDelete={onDeleteSkill}/>*/}
            {/*<Skill skillId='1' name='C++' level='9' onDelete={onDeleteSkill}/>*/}
            {/*<Tag name='Skill name'/>*/}
            {/*<Tag name='Not ready skill' style='red' />*/}
            {/*<Tag name='Very long long long long long long tag name' style='red' />*/}
            {/*<Tag name='+3' style='gray' />*/}
            {/*/!*<button onClick={testJira}>jira</button>*!/*/}
        {/*</div>*/}
    </div>

)

}