import React from 'react'

export const Skill = ({skillId=+new Date(), name='', level=0, css={}, showDel=true, onDelete=(sid) => {console.log('SkillId', sid)}}) => {
    return (
        <div style={{display: 'inline-block', margin: '5px'}}>
            <div className='d-flex justify-start align-center' style={{maxWidth: '300px'}}>

                <div style={{color: '#4bb0b0', border: '1px solid #4bb0b0', borderRadius: '5px', height: '25px', maxWidth: '250px', ...css}} className='d-flex justify-between align-center onHover'>
                    <span style={{
                        color: '#4bb0b0',
                        textAlign: 'center',
                        width: '100%',
                        textOverflow: 'ellipsis',
                        wordWrap: 'normal',
                        whiteSpace: 'nowrap',
                        overflowX: 'hidden',
                        padding: '0 5px'
                    }}>
                        {name}
                    </span>
                    <span style={{
                        height: '100%',
                        backgroundColor: '#4bb0b0',
                        color: '#fff',
                        textAlign: 'center',
                        borderRadius: '3px',
                        width: '34px'
                    }}>
                        {level}
                    </span>
                </div>

                <i
                    style={{cursor: 'pointer', paddingLeft: '3px', display: showDel ? 'block' : 'none'}}
                    className="fa fa-trash-o txt-gray clear-minWidth flow-icon"
                    aria-hidden="true"
                    onClick={()=>{onDelete(skillId)}}
                />
            </div>
        </div>
    )
}