import React, { useContext, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { AuthContext } from "../../context/AuthContext"
import { Loader } from "./Loader"
import { SidebarContext } from "../../context/SidebarContext"
import { useHttp } from "../../hooks/http.hook"
import { useMessage } from "../../hooks/message.hook"

export const ProjectSidebar = () => {
    const { loading, request, error, clearError } = useHttp()
    const prj = useContext(SidebarContext)
    const message = useMessage()
    const history = useHistory()
    const auth = useContext(AuthContext)

    // Активация input для materialize
    useEffect(() => {
        window.M.updateTextFields()
        // Инициализируем Dropdown
        // let el = document.querySelector('.dropdown-trigger')
        // window.M.Dropdown.init(el)
    }, [])

    useEffect(() => {
        message(error)
        if (error === 'Нет авторизации') {
            clearError()
            auth.logout()
            history.push('/auth')
        }
        clearError()
    }, [error, message, clearError, auth, history])

    const mainHandler = async (e) => {
        // update user in context
        // get user
        try {
            const usr = await request('/api/auth/getWithProjetcs', 'POST', { email: auth.user.email }, { authorization: 'Bearer ' + auth.token })
            auth.login(auth.token, auth.user.id, usr.user)

            prj.toggle(false)
            history.push('/')
            prj.toggle(false)

        } catch (e) {
            console.log(e)
        }
    }

    const menuClickHandler = (e, path) => {
        let els = document.querySelectorAll('li')
        els.forEach(el => el.classList.remove('active-menu'))
        if (e.target.tagName === 'LI') {
            e.target.classList.add('active-menu')
        } else if (e.target.tagName === 'A') {
            e.target.parentNode.classList.add('active-menu')
        } else {
            e.target.parentNode.parentNode.classList.add('active-menu')
        } history.push(path)
    }

    // console.log(auth.user)
    if (!auth.user || loading) {
        return <Loader />
    }

    return (
        <>
            {/*BACK TO MAIN*/}
            <div className="row clear-row">
                <div className="col-auto ml-2 mr-2">
                    <h5 className="txt-gray" style={{ cursor: 'pointer' }} onClick={mainHandler}><i className="fa fa-chevron-left" aria-hidden="true" style={{ fontSize: '0.8rem', verticalAlign: 'middle' }} /> Настройки</h5>
                </div>
            </div>

            <div className="row clear-row flex-column text-gray">
                {/*USER PROFILE*/}
                <div className="col-auto ml-2 mr-2">
                    <p style={{ marginBottom: '5px', cursor: 'pointer' }} className=" sb-bolder"><i className="fa fa-user" aria-hidden="true" /> Учетная запись</p>
                    {/*<ul style={{marginTop: '0'}} ref={profileR} className="list1 list1-close">*/}
                    <ul style={{ marginTop: '0', marginBottom: '10px' }} className="">
                        <li className="sb active-menu" onClick={(e) => { menuClickHandler(e, '/user/profile') }}><Link to="#"><i className="fa fa-folder-o opacity-0" aria-hidden="true" /> Профиль</Link></li>
                        <li className="sb" onClick={(e) => { menuClickHandler(e, '/user/password') }}><Link to="#"><i className="fa fa-folder-o opacity-0" aria-hidden="true" /> Пароль</Link></li>
                        <li className="sb" onClick={(e) => { menuClickHandler(e, '/user/notify') }}><Link to="#"><i className="fa fa-folder-o opacity-0" aria-hidden="true" /> Уведомления</Link></li>
                    </ul>
                </div>

                {/*MEMBERS*/}
                {auth.user && auth.user.roles[0].role === 'Admin' ? (
                    <div className="col-auto ml-2 mr-2">
                        <p style={{ marginBottom: '5px' }} className=" sb-bolder"><i className="fa fa-users" aria-hidden="true" /> Администрация</p>
                        <ul style={{ marginTop: '0', marginBottom: '0' }}>
                            <li className="sb" onClick={(e) => { menuClickHandler(e, '/members') }}><Link to="#"><i className="fa fa-folder-o opacity-0" aria-hidden="true" /> Участники</Link></li>
                        </ul>
                    </div>
                ) : (<></>)}

                {/*PROJECT SETTINGS*/}
                {/*<div className="col-auto ml-2 mr-2">*/}
                {/*<ul style={{marginTop: '0'}} >*/}
                {/*<li><Link to={`/project/${prj.id}/general`}><i className="fa fa-cogs" aria-hidden="true" /> General</Link></li>*/}
                {/*<li><Link to={`/project/${prj.id}/labels`}><i className="fa fa-tags" aria-hidden="true" /> Labels</Link></li>*/}
                {/*<li><br/></li>*/}
                {/*<li><Link to={`/project/${prj.id}/tasks`}><i className="fa fa-tasks" aria-hidden="true" /> My tasks</Link></li>*/}
                {/*<li><Link to={`/project/${prj.id}/agents`}><i className="fa fa-hdd-o" aria-hidden="true" /> Agents</Link></li>*/}
                {/*<li><br/></li>*/}
                {/*<li><Link to={`/project/${prj.id}/releases`}><i className="fa fa-flag-o" aria-hidden="true" /> Releases</Link></li>*/}
                {/*<li><Link to={`/project/${prj.id}/testCycles`}><i className="fa fa-refresh" aria-hidden="true" /> Test cycles</Link></li>*/}
                {/*<li><Link to={`/project/${prj.id}/testCases`}><i className="fa fa-map-o" aria-hidden="true" /> Test cases</Link></li>*/}
                {/*<li><Link to={`/project/${prj.id}/records`}><i className="fa fa-video-camera" aria-hidden="true" /> Records</Link></li>*/}
                {/*/!*<li><Link to={`/project/${prj.id}/testCases/1`}><i className="fa fa-video-camera" aria-hidden="true" /> test</Link></li>*!/*/}
                {/*</ul>*/}
                {/*</div>*/}

            </div>
        </>
    )
}