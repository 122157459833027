import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Link, useHistory, useParams } from "react-router-dom"
import { AuthContext } from "../../context/AuthContext"
import { SidebarContext } from "../../context/SidebarContext"
import { useHttp } from "../../hooks/http.hook"
import { useMessage } from "../../hooks/message.hook"
import { Header } from "../partials/Header"
import { Loader } from "../partials/Loader"
import { Tab } from "../partials/Tab"
import { Block } from "./Block"


export const RoadmapPage = () => {
    const id = useParams().id
    const { token, logout } = useContext(AuthContext)
    const sBC = useContext(SidebarContext)
    const history = useHistory()
    const { loading, request, error, clearError } = useHttp()
    const message = useMessage()
    const [project, setProject] = useState(null)
    const [filtered, setFiltered] = useState([])

    const [connected, setConnected] = useState(false)
    const [jiraStatuses, setJiraStatuses] = useState([])
    const [jiraEpics, setJiraEpics] = useState([])
    const [selectedTab, setSelectedTab] = useState('0')

    const [form, setForm] = useState({
        name: '',
        description: '',
        type: '',
        epicId: '',
        epicSummary: ''
    })

    // console.log('form', form)

    useEffect(() => {
        message(error)
        clearError()
    }, [error, message, clearError, logout, history])

    // Активация input для materialize
    useEffect(() => {
        window.M.updateTextFields()
        if (document.querySelectorAll('select')) window.M.FormSelect.init(document.querySelectorAll('select'))
        // if (document.getElementById('description')) window.M.textareaAutoResize(document.getElementById('description'))
        // // Инициализация modal
        // let el = document.querySelector('.modal')
        // if (el) setMd(window.M.Modal.init(el))
    })

    const setEpicStatus = (statuses, epic) => {
        if (epic.tasks.length) {
            if (epic.tasks.filter(ts => ts.status.name === 'В работе').length > 0) {
                epic.status = { ...epic.tasks.filter(ts => ts.status.name === 'В работе')[0].status }
                return
            }

            if (epic.tasks.filter(ts => ts.status.name === 'Готово').length === epic.tasks.length) {
                epic.status = { ...epic.tasks.filter(ts => ts.status.name === 'Готово')[0].status }
            }
        }
    }

    const getProject = useCallback(async (token, id) => {
        try {
            if (token && id) {
                const data = await request(`/api/project/roadmap/${id}`, 'GET', null, { authorization: 'Bearer ' + token })
                setProject(data.project)

                // console.log('project', data.project)

                // Get jira
                if (data.project.jiraPass) {
                    const sk = await request(`/api/jira/roadmap`, 'POST', {
                        jiraName: data.project.jiraName,
                        jiraUrl: data.project.jiraUrl,
                        jiraPass: data.project.jiraPass,
                        prjName: data.project.jiraProject,
                        HRprjName: data.project.jiraHRProject
                    }, { authorization: 'Bearer ' + token })
                    if (sk.connected) {
                        setConnected(true)
                        setJiraStatuses(sk.statuses)
                        // check epic status: all in planning = planning, have one in work = in work, all finished = finished
                        sk.epics.forEach(e => setEpicStatus(sk.statuses, e))

                        setJiraEpics(sk.epics)
                        setFiltered(sk.epics)

                        // console.log('Epics', sk.epics)
                        // console.log('Statuses', sk.statuses)
                    }
                } else {
                    setConnected(false)
                    setJiraStatuses([])
                    setJiraEpics([])
                    setFiltered([])
                }

            }
        } catch (e) {
            console.log(e)
        }
    }, [request])


    const createEpicsInJira = useCallback(async (epics = [], token, id) => {
        // create epics in Jira (epics.name, epics.description)
        const r = await request(`/api/jira/createEpics`, 'POST', {
            jiraName: project.jiraName,
            jiraUrl: project.jiraUrl,
            jiraPass: project.jiraPass,
            prjName: project.jiraProject,
            epics
        }, { authorization: 'Bearer ' + token })

        await getProject(token, id)

    }, [request, getProject, project])

    const createTaskInJira = useCallback(async (task, token, id) => {
        // create task in Jira (form.name, form.description)
        const r = await request(`/api/jira/createTask`, 'POST', {
            jiraName: project.jiraName,
            jiraUrl: project.jiraUrl,
            jiraPass: project.jiraPass,
            prjName: project.jiraHRProject,
            // task


            task: {
                ...task, description: `Эпик: ${task.epicSummary}, Описание задачи для HR: ${task.description}`
            }


        }, { authorization: 'Bearer ' + token })

        await getProject(token, id)

    }, [request, getProject, project, form])

    const getBlocksToCreateEpics = useCallback(async (token, id) => {
        if (project && project.blocks.length && project.archStr) {
            // Get Arch elements
            let loadedEls = JSON.parse(project.archStr)

            // console.log('loadedEls before', loadedEls)

            // get only not deleted blocks
            loadedEls = loadedEls.map(el => {
                if (el.id[0] === 'd') {
                    let pBlock = project.blocks.find(b => b.id === el.data.b.id)
                    if (pBlock) {
                        // el.data.b = {...pBlock}
                        // return el.data.b.id
                        return pBlock.id
                    }
                }
            }).filter(el => el !== undefined)

            // Get unique id of used blocks
            // loadedEls = [...new Set(loadedEls.map(el => el.id))]
            loadedEls = [...new Set(loadedEls)]

            // get really blocks to create epics
            let epicsToCreate = []
            loadedEls.forEach(el => {
                epicsToCreate.push(project.blocks.find(b => b.id === el))
            })

            // console.log('loadedEls after', loadedEls)
            // console.log('epicsToCreate', epicsToCreate)

            // create epics in Jira (epicsToCreate.name, epicsToCreate.description)
            await createEpicsInJira(epicsToCreate, token, id)
        }
    }, [project, token, id])

    useEffect(() => {
        // prj.toggle(true, id)
        getProject(token, id)

    }, [getProject, id, token])

    const showModal = (type = null, epicId = null, summary) => {

        if (!type) return
        setForm({ ...form, name: '', description: '', type, epicSummary: summary })

        // console.log("----------", summary)
        window.M.updateTextFields()
        if (document.querySelectorAll('select')) window.M.FormSelect.init(document.querySelectorAll('select'))

        // Инициализация modal
        let el = document.querySelector('.modal')
        if (el) {
            // setBlockId(id)
            window.M.Modal.init(el).open()
            setTimeout(() => {
                window.M.updateTextFields()
            }, 200)
        }
    }

    const changeHandler = event => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }

    const validate = () => {
        let ok = true

        if (!form.name) {
            document.getElementById('name').classList.add('invalid')
            ok = false
        }

        if (!form.description) {
            document.getElementById('description').classList.add('invalid')
            ok = false
        }


        return ok
    }

    const updateHandler = async () => {
        // console.log('_____________')
        if (!validate()) return

        // console.log('form to save', form)
        try {
            // Here create task or EPIC
            if (form.type && form.type === 'Эпик') {
                await createEpicsInJira([form], token, id)
            }

            if (form.type && form.type === 'Задача') {
                await createTaskInJira(form, token, id)
            }

        } catch (e) {
            console.log(e)
            setForm({ ...form, name: '', description: '', type: '', epicId: '', epicSummary: '' })
        }

        setForm({ ...form, name: '', description: '', type: '', epicId: '', epicSummary: '' })

        // close modal
        let el = document.querySelector('.modal')
        let fade = document.querySelector('.modal-overlay')
        if (fade) fade.parentNode.removeChild(fade)
        if (el) window.M.Modal.init(el).close()

        // getProject(token, id)

    }

    // useEffect(() => {
    //     // console.log('project', project)
    //     console.log('form', form)
    // }, [project, form])

    // on change Tab
    useEffect(() => {
        // Filter epics & tasks
        selectedTab === '0' ? setFiltered(jiraEpics) : setFiltered(jiraEpics.filter(e => e.status.id === selectedTab))
        // console.log('selected tab', selectedTab)
    }, [selectedTab])

    const tabClickHandler = id => {
        setSelectedTab(id)
    }


    if (!project || loading) {
        return <Loader />
    }

    return (
        <>
            <Header params={{
                title: `Блоки`,
                subTitle: 'Управление снипетами',
                bk: [
                    {
                        title: project ? project.name : '',
                        // actionHandler: () => {history.goBack()}
                        actionHandler: () => { }
                    },
                    {
                        title: `Дорожная карта`,
                        actionHandler: () => { }
                    },
                ],
                btnL: {
                    actionHandler: () => {
                        // console.log('Create roadmap')
                        getBlocksToCreateEpics(token, id)
                    },
                    title: 'Создать дорожную карту',
                    display: 'block'
                },
                btnR: {
                    actionHandler: () => {
                        console.log('Создать этап')
                        showModal('Эпик')
                    },
                    title: 'Создать новый этап',
                    display: 'block'
                },
                loading
            }} />

            <div className="row clear-row afterHeader">
                <div className="col s12 mt-2">
                    {project && !connected ?
                        <div style={{ backgroundColor: '#fff4cd', color: '#866607', padding: '10px', textAlign: 'justify' }}>
                            <span style={{ fontWeight: 600 }}>Важно!</span> У вас не настроена интеграция с Jira,
                            поэтому функция прогнозирование ресурсов и связка тегов пока недоступны.
                            Вы можете настроить интеграцию <Link to={`#`} onClick={() => {
                                sBC.toggleMenu('integration')
                                sBC.toggle(false, project.id)
                                history.push(`/project/${project.id}/integrations`)
                            }} >здесь.</Link>
                        </div> :
                        <>
                            <div className="col s12 pl-0 my-1">
                                {/*TABS*/}
                                <div className='mb-1'>
                                    <Tab uid='0' name='All' style={selectedTab === '0' ? 'blue' : 'gray'} onClick={tabClickHandler} css={{ marginLeft: 0 }} />
                                    {jiraStatuses && jiraStatuses.length ?
                                        jiraStatuses.map(st => {
                                            return (
                                                <Tab key={st.id} uid={st.id} name={st.name} style={selectedTab === st.id ? 'blue' : 'gray'} onClick={tabClickHandler} />
                                            )
                                        })
                                        : <></>}
                                </div>

                                {/*BLOCKS*/}
                                <div className='d-flex flex-column justify-start'>
                                    {filtered.length ? filtered.map(e => {
                                        return (
                                            <Block key={e.id} epicId={e.id} epic={e} project={project} onDelete={() => { getProject(token, id) }} onAddHr={showModal} />
                                        )
                                    }) : <></>}
                                </div>
                            </div>
                        </>}
                </div>
            </div>

            {/*Modal*/}
            <div id="modal1" className="modal w-600" style={{ overflowY: 'auto', overflowX: 'hidden' }}>

                <div className="modal-content">
                    <div className="row clear-row flex-row justify-between mb-0" style={{ borderBottom: '1px solid #eee' }}>
                        <div className="col">
                            <h5 style={{ fontWeight: '600', marginTop: 0 }}>{form.type ? form.type === 'Эпик' ? 'Создать новый этап' : 'Поставить задачу в HR' : ''}</h5>
                        </div>
                        <div className="col">
                            <button className="modal-close waves-effect waves-green btn-flat txt-gray" style={{ paddingRight: 0, marginTop: '-5px' }}><i style={{ color: '#eaeaea' }} className="fa fa-times" aria-hidden="true" /></button>
                        </div>
                    </div>

                    <div className="row clear-row flex-row justify-start align-center mb-0">
                        <div className="col s6 ml-0">
                            {/*Name*/}
                            <div className="input-field mr-2 mb-0">
                                <input
                                    type="text"
                                    id="name"
                                    className="validate"
                                    name="name"
                                    style={{ marginBottom: 0 }}
                                    value={form.name}
                                    onChange={changeHandler}
                                />
                                <label htmlFor="name">Имя *</label>
                            </div>
                        </div>
                        <div className="col s6 ml-0">
                            {/*jiraSkills*/}
                            <div className="input-field mr-2 mb-0">
                                <input
                                    type="text"
                                    id="description"
                                    className="validate"
                                    name="description"
                                    style={{ marginBottom: 0 }}
                                    value={form.description}
                                    onChange={changeHandler}
                                />
                                <label htmlFor="description">Описание *</label>
                            </div>
                        </div>
                    </div>

                </div>

                {/*FOOTER SAVE/CANCEL*/}
                <div className="modal-footer overflowHidden" style={{ height: 'auto' }}>
                    <div className="row clear-row justify-end my-1">
                        <div className="col-auto mr-1">
                            {/*Cancel*/}
                            <button
                                style={{ border: '1px solid grey', color: 'black' }}
                                className="waves-effect waves-dark btn white lighten-1 noUpper modal-close"
                                onClick={() => { setForm({ ...form, name: '', description: '', epicSummary: '' }) }}
                            >
                                Отмена
                            </button>
                        </div>

                        <div className="col-auto mr-2">
                            {/*Save changes*/}
                            <button
                                className="waves-effect waves-light btn blue darken-1 noUpper"
                                onClick={updateHandler}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}