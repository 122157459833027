import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { SidebarContext } from "../../context/SidebarContext"
import { MagicSvg } from '../../img/svg'
import { Icon } from "../partials/Icon"
import { ArchitectureRecommendation } from "./ArchitectureRecommendation"
// import {BlockForm} from "./BlockForm"

export const FlowSidebar = ({ project, getProject }) => {
    const sideBar = useContext(SidebarContext)
    const [action, setAction] = useState('Action')
    const [filtered, setFiltered] = useState(project && project.blocks.length ? project.blocks.filter(el => el.type === 'Action') : [])
    const history = useHistory()
    // const [searching, setSearching] = useState(false)

    const onDragStart = (event, nodeType, b) => {
        event.dataTransfer.setData('application/reactflow', nodeType)
        event.dataTransfer.setData('b', JSON.stringify(b))
        event.dataTransfer.effectAllowed = 'move'
    }

    // console.log('SbPrj', project)
    const [rec, setRec] = useState(false)

    useEffect(() => {
        setFiltered(project && project.blocks.length ? project.blocks.filter(el => el.type === 'Action') : [])
    }, [project])
 

    console.log("----", filtered)

    const showModal = () => {
        // Инициализация modal
        // Инициализация modal
        let el = document.querySelector('.modal')
        if (el) {
            // setBlockId(id)
            window.M.Modal.init(el).open()
            setTimeout(() => {
                window.M.updateTextFields()
                if (document.getElementById('description')) window.M.textareaAutoResize(document.getElementById('description'))
            }, 200)
        }
    }

    useEffect(() => {
        // Here goes the code you wish to run on mount

        return () => {
            console.log('unmounting flow sidebar')
        }
    }, [])


    useEffect(() => {
        if (rec) {
            console.log(":::::::::::: RECOMMENDATION ::::::::::::")

            if (action === "Block") {
                setAction('Action')
            } else {
                setAction('Block')
            }
            setAction(prev => prev === 'Action' ? 'Block' : 'Action')
            setRec(false)

        }
    }, [rec])



    return (
        <>
            <aside style={{ zIndex: 1000 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <p id="header" className="py-0">Блоки</p>
                    <div style={{ marginTop: '5px' }} onClick={() => { showModal() }}><MagicSvg onClick={() => { showModal() }} /></div>
                </div>
                <div id="search" className="pt-1">
                    <div className="input-field ml-0 pl-0 my-0 text-gray">
                        <i className="fa fa-search prefix" aria-hidden="true" style={{ width: '24px', height: '15px', fontSize: 'inherit', marginTop: '5px' }} />
                        <input
                            placeholder="Поиск"
                            type="text"
                            id="blck-search"
                            className=""
                            style={{ marginLeft: '24px', width: 'calc(100% - 30px)', borderBottom: 'none' }}
                            name="search"
                            autoComplete="off"
                            onKeyUp={(key) => {
                                // setSearching(true)
                                key.target.value
                                    ? setFiltered(project && project.blocks.length ? project.blocks.filter(el => el.name.toLowerCase().includes(key.target.value.toLowerCase()) || el.description.toLowerCase().includes(key.target.value.toLowerCase())) : [])
                                    : setFiltered(project && project.blocks.length ? project.blocks : [])
                            }}
                        />
                    </div>
                </div>


                <div id="subnav" className="d-flex justify-between align-center">


                    <div id="actions" className={`side d-flex justify-center ${action === 'Action' ? 'navactive' : 'navdisabled'}`} onClick={() => {
                        setAction('Action')
                        // setSearching(false)
                        setFiltered(project && project.blocks.length ? project.blocks.filter(el => el.type === 'Action') : [])
                        document.getElementById('blck-search').value = ''
                    }}>
                        <div className="d-flex justify-center align-center">
                            <Icon name='flash' size='20px' mt='2px' />
                            <span>Действия</span>
                        </div>
                    </div>
                    <div id="loggers" className={`side d-flex justify-center ${action === 'Block' ? 'navactive' : 'navdisabled'}`} onClick={() => {
                        setAction('Block')
                        // setSearching(false)
                        setFiltered(project && project.blocks.length ? project.blocks.filter(el => el.type === 'Block') : [])
                        document.getElementById('blck-search').value = ''
                    }}>
                        <div className="d-flex justify-center align-center">
                            <Icon name='box' size='20px' mt='2px' />
                            <span>Блоки</span>
                        </div>
                    </div>


                </div>



                <div id="blocklist">
                    {filtered && filtered.length ? filtered.map(b => {
                        return (
                            <React.Fragment key={b.id}>
                                <div className="blockelem create-flowy noselect dndnode d-flex justify-start" onDragStart={(event) => onDragStart(event, 'special', b)} draggable>
                                    <div className="grabme">
                                        {b.type === 'Action' ? <Icon name='flash' size='20px' mt='-6px' /> : <Icon name='box' size='20px' mt='-6px' />}
                                    </div>
                                    <div className="blockin d-flex flex-column">
                                        <div className="blocktext">
                                            <p className="blocktitle">{b.name}</p>
                                            <p className="blockdesc">{b.description}</p>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>)
                    }) : <></>}
                </div>



                <div id="footer" className="d-flex justify-center align-center">
                    <div className='addBtn txt-gray' onClick={() => {
                        history.push(`/project/${project.id}/blocks`)
                        sideBar.toggle(false, project.id)
                        sideBar.toggleMenu('block')
                    }}>
                        {action === 'Action' ? '+ Добавить действие' : '+ Добавить блок'}
                    </div>
                </div>
            </aside >
            < ArchitectureRecommendation project={project} getProject={getProject} setRec={setRec} />
        </>
    )
}