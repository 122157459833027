import React, {useContext} from 'react'
import {useState, useEffect, useRef} from "react";
import {useHttp} from "../../hooks/http.hook"
import {Loader} from "../partials/Loader"
import {useMessage} from "../../hooks/message.hook"
import {useHistory} from "react-router-dom"
import {AuthContext} from "../../context/AuthContext"
import {SidebarContext} from "../../context/SidebarContext"

export const UserProfilePage = () => {
    const { token, user, logout } = useContext(AuthContext)
    const {loading, request, error, clearError, fileUpload} = useHttp()
    const prj = useContext(SidebarContext)
    const [form, setForm] = useState({
        email: '',
        name: '',
        img: ''
    })
    const message = useMessage()
    const inputFile = useRef(null)
    const history = useHistory()

    useEffect(() => {
        message(error)
        if (error === 'Нет авторизации') {
            clearError()
            logout()
            history.push('/')
        }
        clearError()
    }, [error, message, clearError, logout, history])

    // Активация input для materialize
    useEffect(() => {
        window.M.updateTextFields()
    })

    useEffect(() => {
        try {
            if (user) {
                setForm({
                    email: user.email,
                    name: user.name,
                    img: user.photo ? `${user.photo}?v=${Date.now()}` : `/assets/userImg/no.png`
                })
            }
            prj.toggle(true, null)
        } catch (e) {
            console.log(e)
        }
    }, [user, prj])

    const changeHandler = event => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }

    const imgChangeHandler = async event => {
        try {
            const fd = new FormData();
            fd.append('avatar', event.target.files[0])

            const data = await fileUpload('/api/auth/upload', 'POST', fd, {authorization: 'Bearer ' + token, id: user.id})

            setForm({ ...form, img: `/assets/userImg/${user.id}.jpg?v=${Date.now()}` })
            message(data.message)
        } catch (e) {
            console.log(e)
        }
    }

    const updateHandler = async () => {
        try {
            const data = await request('/api/auth/updateProfile', 'POST', {...form}, {authorization: 'Bearer ' + token})
            message(data.message)
        } catch (e) {}
    }

    if (!user) {
        return <Loader />
    }
    return (
        <>
            <div className="row clear-row mt-noHeader">
                <div className="col-auto">
                    <h5>Профиль</h5>
                    <p className="txt-gray">Управление профилем</p>
                </div>
            </div>

            <div className="row clear-row flex-column">
                <div className="col-auto">
                    <p>Изображение профиля</p><br/>
                    <img
                        style={{borderRadius: '50%', overflow: 'hidden', cursor: 'pointer', width: '75px', height: '75px', border: '1px solid grey'}}
                        // todo check if exists`/assets/userImg/${props.user.id}.jpg`
                        src={form.img}
                        alt=""
                        onClick={() => {
                            inputFile.current.click()
                        }}
                    />
                    <input
                        type='file'
                        id="avatar"
                        ref={inputFile}
                        style={{display: 'none'}}
                        // value={form.img}
                        onChange={imgChangeHandler}
                        accept=".jpg"
                    />
                </div>

                <div className="col s4 mt-1 ml-0 pl-0">
                    <div className="input-field">
                        <input
                            placeholder="Введите email"
                            type="text"
                            id="email"
                            className=""
                            name="email"
                            value={form.email}
                            autoComplete="off"
                            onChange={changeHandler}
                        />
                        <label htmlFor="email">Электронная почта</label>
                    </div>

                    <div className="input-field">
                        <input
                            placeholder="Имя пользователя"
                            type="text"
                            id="name"
                            className=""
                            name="name"
                            value={form.name}
                            onChange={changeHandler}
                        />
                        <label htmlFor="name">Имя пользователя</label>


                        <button
                            className="waves-effect waves-light btn blue darken-1 noUpper"
                            onClick={updateHandler}
                            disabled={loading}
                        >
                            Обновить
                        </button>
                    </div>

                </div>

            </div>
        </>
    )
}