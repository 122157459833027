import React, {useState, useEffect, useCallback, useContext} from 'react'
import {Icon} from "../partials/Icon"
import {AuthContext} from "../../context/AuthContext"
import {useHttp} from "../../hooks/http.hook"

export const FlowProps = ({block, node, onCloseProps, onDeleteAll, updateBlock}) => {
    const { token } = useContext(AuthContext)
    const {silentRequest} = useHttp()
    console.log('block', block)

    useEffect(() => {
        if (document.querySelectorAll('select')) window.M.FormSelect.init(document.querySelectorAll('select'))
    })

    const changePropHandler = useCallback(async (e, propId) => {
        console.log('Selected opt id', e.target.value)
        // console.log('prop id', propId)
        let optId = +e.target.value

        try {
            // save property.selectedId = e.target.value
            const data = await silentRequest(`/api/block/propUpd`, 'POST', {optId, propId }, {authorization: 'Bearer ' + token})
            updateBlock(block.id, propId, optId)
        } catch (e) {
            console.log(e)
        }
    }, [silentRequest, block])

    return (
        <div className='propWrap' style={{zIndex: 1000}}>
            <span className='closeProps'
                  onClick={onCloseProps}
            >
                &times;
            </span>

            <p id="header" className="py-0">{block ? `"${block.name}" properties` : 'Properties'}</p>

            <div style={{position: 'absolute', left: 0, right: 0, top: '50px', borderBottom: '1px solid #eff1f4'}}/>

            <div id="blocklist" className='mt-1'>
                {block && block.properties.length ? block.properties.map(p => { return (
                    <React.Fragment key={p.id}>
                        {p.type === 'Text'
                            ?
                            <>
                                <p className='mb-1'>{`${p.type}: ${p.name}`}</p>
                            </>
                            :
                            // if type === 'Select' this show id of selected option
                            <>
                                <div className="input-field">
                                    <select
                                        id={`Ptype-${p.id}`}
                                        name={`opt-${p.id}`}
                                        value={p.selectedId}
                                        onChange={(e) => {
                                            changePropHandler(e, p.id)
                                        }}
                                    >
                                        <option value="0">-</option>
                                        { p.options.length ? p.options.map(o => <option key={o.id} value={o.id}>{o.text}</option>) : (<></>)}
                                    </select>
                                    <label htmlFor={`Ptype-${p.id}`}>{p.name}</label>
                                </div>
                            </>
                        }
                    </React.Fragment>)
                }) : <></>}
            </div>

            <div id="footer" className="d-flex justify-center align-center">
                <div className='addBtn txt-gray' onClick={() => {onDeleteAll(node ? node.id : 0)}}>
                    Удалить блок
                </div>
            </div>
        </div>
    )
}