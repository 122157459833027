import {createContext} from 'react'

function noop() {}

export const SidebarContext = createContext({
    isProject: false,
    id: null,
    menuId: null,
    toggleMenu: noop,
    toggle: noop
})