import React, {useContext, useEffect, useState} from 'react'
import {SidebarContext} from "../../context/SidebarContext"
import {AuthContext} from "../../context/AuthContext"
import {useHttp} from "../../hooks/http.hook"
import {useMessage} from "../../hooks/message.hook"
import {Loader} from "../partials/Loader"

export const UserNotifyPage = () => {
    const { token, user, login } = useContext(AuthContext)
    const {loading, request} = useHttp()
    const message = useMessage()
    const prj = useContext(SidebarContext)
    const [form, setForm] = useState({
        notify: 0,
        invite: 0,
    })

    useEffect(() => {
        try {
            if (user) {
                console.log('user', user)
                setForm({
                    notify: user.notify,
                    invite: user.invite,
                })
            }
            prj.toggle(true, null)
        } catch (e) {
            console.log(e)
        }
    }, [user, prj])

    // useEffect(async () => {
    //     // prj.toggle(true, null)
    //     // console.log(form)
    //     // login(token, user.id, {...user, ...form})
    // }, [form])

    const changeHandler = async event => {
        // console.log(event.target.checked)
        setForm({ ...form, [event.target.name]: event.target.checked })

        const data = await request('/api/auth/updateNotify', 'POST', {userId: user.id, notify: {...form, [event.target.name]: event.target.checked}}, {authorization: 'Bearer ' + token})
        message(data.message)

        login(token, user.id, {...user, ...data.user})
    }

    if (!user || loading) {
        return <Loader />
    }

    return (
        <>
            <div className="row clear-row mt-noHeader">
                <div className="col-auto">
                    <h5>Уведомления</h5>
                    <p className="txt-gray">Выберите, где и когда вы будете уведомлены</p>
                </div>
            </div>

            <div className="row clear-row flex-row">
                <div className="col s10 mt-3 ml-0 pl-0">
                    <p style={{fontSize: '1.1rem'}}>Рассылка по электронной почте</p>
                    <p className="txt-gray">Получайте по электронной почте дайджест непрочитанных уведомлений. Уведомления группируются и отправляются в зависимости от срочности уведомлений.</p>
                </div>

                <div className="col s2 mt-3 ml-0 clear-row justify-around align-center">
                    <div className="switch">
                        <label>
                            {/*Off*/}
                            <input type="checkbox"
                                name="notify"
                                checked={form.notify}
                                onChange={changeHandler}
                            />
                            <span className="lever" />
                            {/*On*/}
                        </label>
                    </div>
                </div>
            </div>

            <div className="row clear-row flex-row">
                <div className="col s10 mt-1 ml-0 pl-0">
                    <p style={{fontSize: '1.1rem'}}>Приглашение принято</p>
                    <p className="txt-gray">Получите электронное письмо, когда приглашенный участник примет ваше приглашение</p>
                </div>

                <div className="col s2 mt-3 ml-0 clear-row justify-around align-center">
                    <div className="switch">
                        <label>
                            {/*Off*/}
                            <input type="checkbox"
                               name="invite"
                               checked={form.invite}
                               onChange={changeHandler}
                            />
                            <span className="lever" />
                            {/*On*/}
                        </label>
                    </div>
                </div>
            </div>
        </>
    )
}